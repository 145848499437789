import React from "react";
import Footer from "./footer";
import TheContent from "./TheContent";
import TheHeader from "./TheHeader";

const TheLayout = () => {
  return (
    <div>
      <TheHeader />
      <div>
        <TheContent />
      </div>
      <Footer />
    </div>
  );
};

export default TheLayout;
