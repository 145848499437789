import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer_1 custom-bg-purple custom-yellow">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <div className="footer_logo">
                <Link to="/">
                  <img
                    src="avatar/zg-logo.svg"
                    alt="zg"
                    height="auto"
                    width="100px"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget nav-menus">
                <h2 className="widget_title">Company</h2>
                <ul className="custom-list-cl">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/projects">Projects</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/blog/news">Blog</Link>
                  </li>
                  {/* <li>
                    <a href="shop.html">Shop</a>
                  </li> */}
                  <li>
                    <Link to="/contact">Get in Touch</Link>
                  </li>
                  {/* <li>
                    <a href="service.html">Services</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="clearfix hidden-sm hidden-md hidden-lg" />
            {/* <div className="col-lg-3 col-md-6">
              <div className="widget">
                <h2 className="widget_title">Services</h2>
                <ul className="custom-list-cl">
                  <li>
                    <a href="service_details.html">
                      Web Design &amp; Development
                    </a>
                  </li>
                  <li>
                    <a href="service_details.html">Mobile App Design</a>
                  </li>
                  <li>
                    <a href="service_details.html">
                      Logo Design &amp; Branding
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-6 noPaddingLeft">
              <div className="widget socialicons">
                <h2 className="widget_title">Get Social</h2>
                <ul className="custom-list-cl">
                  <li>
                    <a
                      href="https://www.facebook.com/ZeroGravityAfrica"
                      className="fac"
                    >
                      <i className="fa fa-facebook" />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://vimeo.com/user67180687" className="twi">
                      <i className="fa fa-vimeo" /> Vimeo
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/ZeroGravityAfrica"
                      className="goo"
                    >
                      <i className="fa fa-behance" />
                      Behance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/ZeroGravityAfrica"
                      className="dri"
                    >
                      <i className="fa fa-instagram" />
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 noPaddingLeft">
              <div className="widget">
                <h2 className="widget_title">Visit Us</h2>
                <div className="contactContents">
                  <p>
                    <a
                      className="custom-yellow"
                      href="mailto:hello@zerogravity.com"
                    >
                      hello@zerogravity.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="copy_right_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>© 2021 Zero gravity studios. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
