import React from "react";
import Internship from "./views/careers/internship";
import Jobs from "./views/careers/jobs";

const Home = React.lazy(() => import("./views/home"));
const About = React.lazy(() => import("./views/aboutUs"));
const Contact = React.lazy(() => import("./views/contact"));
const Amina = React.lazy(() => import("./views/originals/aminaJourney"));
const Kore = React.lazy(() => import("./views/originals/kore"));
const Kweeto = React.lazy(() => import("./views/originals/kweeto"));
const News = React.lazy(() => import("./views/blogs/news"));
const Features = React.lazy(() => import("./views/blogs/features"));
const Training = React.lazy(() => import("./views/careers/training"));
const Project = React.lazy(() => import("./views/project"));
const Awards = React.lazy(() => import("./views/awards"));

const routes = [
  { path: "/", exact: true, component: Home },
  { path: "/dashboard", name: "Dashboard", component: Home },
  { path: "/about", name: "Dashboard", component: About },
  { path: "/contact", name: "Dashboard", component: Contact },
  { path: "/originals/amina", name: "Dashboard", component: Amina },
  { path: "/originals/kore", name: "Dashboard", component: Kore },
  { path: "/originals/kweeto", name: "Dashboard", component: Kweeto },
  { path: "/blog/news", name: "Dashboard", component: News },
  { path: "/blog/features", name: "Dashboard", component: Features },
  { path: "/career/internship", name: "Dashboard", component: Internship },
  { path: "/career/jobs", name: "Dashboard", component: Jobs },
  { path: "/career/training", name: "Dashboard", component: Training },
  { path: "/project", name: "Dashboard", component: Project },
  { path: "/awards", name: "Awards", component: Awards },
];

export default routes;
