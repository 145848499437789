import React from "react";
import { Link } from "react-router-dom";

const TheHeader = () => {
  return (
    <header className="custom-bg-purple">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3">
            <div className="logo">
              <Link to="/">
                <img
                  src="avatar/zg-logo.svg"
                  alt="zg"
                  height="auto"
                  width="40px"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 menu-col">
            <a href="javascript:void(0)" className="menu_btn">
              <i className="la la-bars" />
            </a>
            <nav className="mainMenu_3 mainmenu">
              <ul>
                {/* <li className="current-menu-item menu-item-has-children">
                  <Link to="/">Home</Link>
                </li> */}
                {/* <li>
                  <Link to="/about">About Us</Link>
                </li> */}
                <li className="menu-item-has-children">
                  <a href="#">Studio</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/awards">Awards</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/project">Projects</Link>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Originals</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/originals/kweeto">Kweeto</Link>
                    </li>
                    <li>
                      <Link to="/originals/kore">Kore</Link>
                    </li>
                    <li>
                      <Link to="/originals/amina">Aminah's Journey</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Careers</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/career/jobs">Jobs</Link>
                    </li>
                    <li>
                      <Link to="/career/training">Training</Link>
                    </li>
                    <li>
                      <Link to="/career/internship">Internship</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <a href="#">Blog</a>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/blog/news">News</Link>
                    </li>
                    <li>
                      <Link to="/blog/features">Features</Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <a href="contact.html">Get In touch</a>
                </li> */}
              </ul>
            </nav>
          </div>
          {/* <div className="col-lg-2 col-md-1">
            <div className="navigator_right text-right">
              <a href="javascript:void(0)" className="naviSearch">
                <i className="noun-noun_Search_1534856" />
              </a>
              <a href="#" className="naviUser">
                <i className="noun-noun_account_847124" />
              </a>
              <a href="#" className="naviCart">
                <span>0</span>
                <i className="noun-noun_cart_1231977" />
              </a>
              <a href="javascript:void(0)" className="navigator">
                <span />
                <span />
                <span />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default TheHeader;
