import React, { Suspense, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../routes";

const TheContent = () => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <Fragment>
      <Suspense fallback={loading}>
        <Switch>
          {
            // !authData ? (
            //   <Redirect to="/login" />
            // ) : (
            routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })
            // )
          }
          {/* <Redirect from="/" to="/dashboard" /> */}
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default TheContent;
