import React, { Component } from "react";
import {
  // HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import TheLayout from "./containers/TheLayout";
import "./custom-css/customStyle.css";

function App() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
